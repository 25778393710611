export const environment = {
  production: true,
  url_api: "/api",
  url_vpn: "/vpn",
  keycloak: {
    enable: true,
    authority: 'https://sso.dbt.ma',
    redirectUri: 'https://rec.cloud.dbt.ma/',
    postLogoutRedirectUri: 'https://rec.cloud.dbt.ma/logout',
    realm: 'master',
    clientId: 'cloud-app',
  },
};
