import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class VpnCheckService {
  private allowedVpnIps: string[] = ['154.144.226.251'];
  private ip: string | null = null; // Use null to indicate uninitialized state

  constructor(private http: HttpClient) {
  }

// Method to fetch the user's IP address
  getUserIp(): Observable<string> {
    console.log(`Get User IP`);

    return this.http.get<{ ip: string }>(environment.url_vpn + '/get-ip-public').pipe(
      map(response => response.ip),
      tap(ip => {
        this.ip = ip; // Store the IP upon retrieval
        console.log("Fetched IP: " + ip);
      }),
      catchError(err => {
        console.error("Error fetching IP: ", err);
        return throwError("VPN Error: " + err);
      })
    );
  }

  // Method to check if the IP is within the allowed list
  isConnectedToVpn(): Observable<boolean> {
    console.log(`Check IP`);

    // If IP is already fetched, just check it
    if (this.ip) {
      console.log(`Using cached IP: ${this.ip}`);
      const isAllowed = this.allowedVpnIps.includes(this.ip);
      console.log(`Is the cached IP allowed? ${isAllowed}`);
      return new Observable<boolean>(observer => {
        observer.next(isAllowed);
        observer.complete();
      });
    }

    // If IP is not fetched, fetch it and then check
    return this.getUserIp().pipe(
      map(ip => {
        const isAllowed = this.allowedVpnIps.includes(ip);
        console.log(`Is the fetched IP allowed? ${isAllowed}`);
        return isAllowed;
      })
    );
  }
}
